import csstype.*
import emotion.react.css
import kotlinx.browser.document
import kotlinx.browser.window
import react.*
import react.dom.client.createRoot
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.a
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import kotlinx.js.jso
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.CircularProgress
import mui.material.CssBaseline
import mui.material.PaletteMode.light
import mui.system.ThemeOptions
import mui.system.ThemeProvider
import mui.system.createTheme
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.p
import kotlin.js.json

fun main() {
    window.onload = {
        val root = createRoot(document.getElementById("root") ?: error("No root!"))

        root.render(App.create())
    }
}

enum class StatusStage {
    STORING, RETRIEVING, DONE
}

data class Status (
    val stage: StatusStage,
    val response: Response?
)

interface Response {
    val name: String
    val url: String
}

val App = FC<Props> { props ->
    var (status, setStatus) = useState<Status>(Status(StatusStage.STORING, null))

    useEffect(null) {
        MainScope().launch {
            window
                .fetch("https://us-central1-plotnet.cloudfunctions.net/storeLatest")
                .await()
            setStatus(Status(StatusStage.RETRIEVING, null))

            val res = window
                .fetch("https://us-central1-plotnet.cloudfunctions.net/getLatest")
                .await()
                .text()
                .await()
            setStatus(Status(StatusStage.DONE, JSON.parse<Response>(res)))
        }
    }

    div {
        when (status.stage) {
            StatusStage.STORING ->
                div {
                    css {
                        width = 100.vw
                        height = 100.vh
                        display = Display.flex
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                        flexDirection = FlexDirection.column
                    }
                    CircularProgress {}
                    p {
                        + "Fetching latest version..."
                    }
                }
            StatusStage.RETRIEVING ->
                div {
                    css {
                        width = 100.vw
                        height = 100.vh
                        display = Display.flex
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                        flexDirection = FlexDirection.column
                    }
                    CircularProgress {}
                    p {
                        +"Retrieving URL..."
                    }
                }
            StatusStage.DONE ->
                div {
                    css {
                        width = 100.vw
                        height = 100.vh
                        display = Display.flex
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                        flexDirection = FlexDirection.column
                    }
                    p {
                        + "Latest: ".plus(status.response!!.name)
                    }
                    Button {
                        href = status.response!!.url
                        variant = ButtonVariant.contained
                        + "Download!"
                    }
                    p {
                        + "Install with "
                        a {
                            href="https://fabricmc.net/"
                            target=AnchorTarget._blank
                            + "Fabric"
                        }
                    }
                }
        }
    }
}